import { lazy } from "@loadable/component";
import { FC } from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import ProtectedRoute from "auth/ProtectedRoute";
import { LoadingFullpage } from "components/Loading";
import { useAuth0 } from "@auth0/auth0-react";

export const history = createBrowserHistory();

export enum routesEnum {
  login = "/login",
  disableMFA = "/mfa/disable",
  mfa = "/mfa/enable",
  home = "/",
  verifyEmail = "/email-verification",
  VerifyEmailStatus = "/email-verification-result",
  temporyRoute = "/tempory",
  privacy = "/privacy",
  term = "/terms",
  active = "/active",
  exit = "/exit",
}

type CustomRouteProps = RouteProps & {
  private?: boolean;
  nonLayout?: boolean;
};
export const routes: CustomRouteProps[] = [
  {
    path: routesEnum.disableMFA,
    exact: true,
    component: lazy(() => import("./DisableMFA")),
    private: true,
    nonLayout: true,
  },
  {
    path: routesEnum.login,
    exact: true,
    component: lazy(() => import("./LoginPage")),
  },
  {
    path: routesEnum.mfa,
    exact: true,
    component: lazy(() => import("./MFA")),
    private: true,
    nonLayout: true,
  },
  {
    path: routesEnum.verifyEmail,
    exact: true,
    component: lazy(() => import("./VerifyEmail")),
  },
  {
    path: routesEnum.VerifyEmailStatus,
    exact: true,
    component: lazy(() => import("./VerifyEmailStatus")),
  },
  {
    path: routesEnum.home,
    exact: true,
    component: lazy(() => import("./HomePage")),
  },
  {
    path: routesEnum.temporyRoute,
    exact: true,
    component: lazy(() => import("./HomePage")),
  },
  {
    path: routesEnum.privacy,
    exact: true,
    component: lazy(() => import("./Privacy")),
    nonLayout: true,
  },
  {
    path: routesEnum.term,
    exact: true,
    component: lazy(() => import("./Term")),
    nonLayout: true,
  },
  {
    path: routesEnum.active,
    exact: true,
    component: lazy(() => import("./ActiveUser")),
    nonLayout: true,
  },
  {
    path: routesEnum.exit,
    exact: true,
    component: lazy(() => import("./Exit")),
    nonLayout: false,
  },
];

export const Routes: FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingFullpage />;
  }
  return (
    <Switch>
      {routes.map((route) =>
        route?.private ? (
          <ProtectedRoute
            path={route.path}
            key={route.path as string}
            exact={route.exact}
            component={route.component}
          />
        ) : (
          <Route
            path={route.path}
            key={route.path as string}
            exact={route.exact}
            component={route.component}
          />
        )
      )}
    </Switch>
  );
};
