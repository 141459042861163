import { Layout } from "components/Layout";
import { LoadingFullpage } from "components/Loading";
import { Routes } from "pages/Routes";

import { useFontObserver } from "hooks";

import "./App.less";
const loadingFullpage = <LoadingFullpage />;

function App() {
  const fontLoaded = useFontObserver();
  return (
    <Layout>
      {!fontLoaded && loadingFullpage}
      <Routes />
    </Layout>
  );
}

export default App;
