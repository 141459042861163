import { FC } from "react";
import { Menu } from "antd";
import Logo from "assets/images/Logo.png";
import { Link } from "react-router-dom";
import styles from "./NavBar.module.css";
import clsx from "clsx";
import { useAuth0 } from "@auth0/auth0-react";

// const navigationRoutes = [
//   { title: "Solutions", path: "/test1" },
//   { title: "Partners", path: "/test2" },
// ];

const NavBar: FC = () => {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
  const pathName = window.location.pathname;

  const renderNavbar = () => {
    if (
      pathName === "/email-verification-result" ||
      pathName === "/email-verification"
    ) {
      return "";
    } else {
      return (
        <div className={styles.content}>
          <Link to="/">
            <img src={Logo} className={styles.logo} />
          </Link>
          <Menu mode="horizontal" className={styles.menu}>
            {/* {navigationRoutes.map((route) => (
              <Menu.Item
                key={route.path}
                onClick={() => history.push("/tempory")}
              >
                {route.title}
              </Menu.Item>
            ))} */}
          </Menu>
          <div className={styles.rightMenu}>
            <div className={clsx(styles.btnLoginWrapper)}>
              {isAuthenticated ? (
                <>
                  <button
                    type="button"
                    className={clsx(styles.btnLoginContent, "cursor")}
                    onClick={() => logout({ returnTo: window.location.origin })}
                  >
                    LOG OUT
                  </button>
                  <img
                    src="https://skipid-prod-web.s3.amazonaws.com/arrow-right-transparent.png"
                    alt="triangle"
                    className={styles.triangle}
                  />
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className={clsx(styles.btnLoginContent, "cursor")}
                    onClick={() => loginWithRedirect()}
                  >
                    LOG IN
                  </button>
                  <img
                    src="https://skipid-prod-web.s3.amazonaws.com/arrow-right-transparent.png"
                    alt="triangle"
                    className={styles.triangle}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  return <div className={styles.root}>{renderNavbar()}</div>;
};

export default NavBar;
