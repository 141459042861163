import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface TKYCReducer {
  firstName: string;
  lastName: string;
  birthday: string;
  identityNumber: string;
  documentType: number;
  country: string;
  countryCode: string;
  address: string;
  city: string;
  prefectures: string;
  postcode: string;
  phoneNumber: string;
  usCitizen: boolean;
}

const initialState = {
  firstName: "",
  lastName: "",
  birthday: "",
  identityNumber: "",
  documentType: 1,
  country: "",
  countryCode: "",
  address: "",
  city: "",
  prefectures: "",
  postcode: "",
  phoneNumber: "",
  usCitizen: false,
} as TKYCReducer;

const kycSlice = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    setKYCData(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const getKYC = (state: RootState): TKYCReducer => state.kyc;

export const { setKYCData } = kycSlice.actions;
export default kycSlice.reducer;
