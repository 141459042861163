import { FC } from "react";

import styles from "./Footer.module.css";
import Logo from "assets/images/Logo.png";
import clsx from "clsx";

export const Footer: FC = () => {
  return (
    <div className={styles.parentRoot}>
      <div className={styles.root}>
        <div className={styles.footerContainer}>
          <div className="colum1">
            <p>
              <img
                src="https://skipid-prod-web.s3.amazonaws.com/arrow-footer.png"
                alt=""
              />
              <a href="/terms" target="_blank">
                Terms of Use
              </a>
            </p>
            {/* <p>
              <img
                src="https://skipid-prod-web.s3.amazonaws.com/arrow-footer.png"
                alt=""
              />
              <a href="#"> Solutions </a>
            </p>
            <p>
              <img
                src="https://skipid-prod-web.s3.amazonaws.com/arrow-footer.png"
                alt=""
              />
              <a href="#"> Start Using </a>
            </p>
            <p>
              <img
                src="https://skipid-prod-web.s3.amazonaws.com/arrow-footer.png"
                alt=""
              />
              <a href="#"> API Documentation </a>
            </p> */}
          </div>
          <div className="colum2">
            {/* <p>
              <img
                src="https://skipid-prod-web.s3.amazonaws.com/arrow-footer.png"
                alt=""
              />
              <a href="/term" target="_blank">
                Terms of Use
              </a>
            </p> */}
            <p>
              <img
                src="https://skipid-prod-web.s3.amazonaws.com/arrow-footer.png"
                alt=""
              />
              <a
                href="https://skipid.zendesk.com/hc/en-001/articles/4414358656271-Privacy-Policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy &amp; Data Protection
              </a>
            </p>
          </div>
          <div className="colum3">
            <img
              src="https://skipid-prod-web.s3.amazonaws.com/arrow-footer.png"
              alt=""
            />

            <a
              href="https://skipid.zendesk.com/hc/en-001"
              target="_blank"
              rel="noreferrer"
            >
              Help Center
            </a>
          </div>
        </div>
        <div
          className={clsx(
            "d-flex f-column f-align-end",
            styles.footerRightContainer
          )}
        >
          <img src={Logo} alt="" />
          <div className={clsx("d-flex f-column f-align-end")}>
            <p className={clsx(styles.introText1, "bolder")}>SKIP ALL LOGINS</p>
            <p className={clsx(styles.introText2, "bolder")}>
              WITH SECURE SIGN-ON
            </p>
            <p className={styles.introText3}>The secure & fast cloud</p>
            <p className={styles.introText3}>single sign-on solution</p>
          </div>
        </div>
      </div>
      <p className={styles.footerSecurity}>&copy;2021 powered by CROSS TECH</p>
    </div>
  );
};

export default Footer;
