import { FC } from "react";
import { useHistory } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import {
  AUTH0_AUDIENCE_MFA,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  SCOPE_MFA,
} from "utils/constant";

const Auth0ProviderWithHistory: FC = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState: AppState) =>
    history.push(appState?.returnTo || window.location.pathname);

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      audience={AUTH0_AUDIENCE_MFA}
      scope={SCOPE_MFA}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
