// src/auth/protected-route.js

import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { LoadingFullpage } from "components/Loading";

const ProtectedRoute = ({ component, ...args }: any) => {
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: function LoadingPage() {
          return <LoadingFullpage />;
        },
      })}
      {...args}
    />
  );
};

export default ProtectedRoute;
