export const LOCAL_STORAGE = {
  accessToken: "access-token",
  userInfo: "user-info",
  language: "i18nextLng",
  redirectUrl: "redirect-url",
};

export const LANGUAGE = ["en-US", "tr-TR"];

export const ULTOREX_URL = process.env.REACT_APP_ULTOREX_URL as string;

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
export const AUTH0_AUDIENCE = process.env
  .REACT_APP_AUTH0_CLIENT_AUDIENCE as string;
export const AUTH0_AUDIENCE_MFA = process.env
  .REACT_APP_AUTH0_CLIENT_AUDIENCE_MFA as string;

export const SCOPE_MFA = "enroll read:authenticators remove:authenticators";

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string;
