import { combineReducers } from "@reduxjs/toolkit";

import userReducer from "./user/slice";
import systemReducer from "./system/slice";
import kycReducer from "./kyc/slice";

const createRootReducer = () => {
  return combineReducers({
    user: userReducer,
    system: systemReducer,
    kyc: kycReducer,
  });
};

export default createRootReducer;
