import { FC, useMemo } from "react";
import styles from "./Layout.module.css";
import { Layout as AntdLayout } from "antd";
import { ConfigProvider as AntdConfigProvider } from "antd";
import { TFunction, useTranslation } from "react-i18next";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { routes } from "pages/Routes";
import { useLocation } from "react-router";

const getValidateMessages = (t: TFunction<"translation">) => ({
  required: t("form.validate.required"),
});

export const Layout: FC = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const validateMessages = useMemo(() => getValidateMessages(t), [t]);

  const currentRoute = useMemo(() => {
    return routes.find((element) => element.path === location.pathname);
  }, [location]);

  return (
    <AntdConfigProvider
      form={{
        validateMessages,
      }}
    >
      <AntdLayout className={styles.root}>
        {!currentRoute?.nonLayout && <NavBar />}
        <main className={styles.main}>{children}</main>
        {!currentRoute?.nonLayout && <Footer />}
      </AntdLayout>
    </AntdConfigProvider>
  );
};
