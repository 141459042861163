import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { LOCAL_STORAGE } from "utils/constant";

export interface TSystemReducer {
  language: string;
  languageType: number;
}

const initialState = {
  language: localStorage.getItem(LOCAL_STORAGE.language),
  languageType:
    localStorage.getItem(LOCAL_STORAGE.language) === "en-US" ? 2 : 5,
} as TSystemReducer;

const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<string>) {
      return {
        ...state,
        language: action.payload,
      };
    },
    setLanguageType(state) {
      return {
        ...state,
        languageType: state.language === "en-US" ? 2 : 5,
      };
    },
  },
});

export const getSystem = (state: RootState): TSystemReducer => state.system;

export const { setLanguage, setLanguageType } = systemSlice.actions;
export default systemSlice.reducer;
